import React, { FC } from "react"

import { graphql, PageProps } from "gatsby"
import { EdgesNode, RemarkQueryType } from "../../types"

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { sourceName: { eq: "blogposts" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

type QueryProps = {
  data: {
    allMarkdownRemark: RemarkQueryType<
      {
        title: string
      },
      {
        slug: string
      }
    >
  }
}

type Props = PageProps & QueryProps

const PostOverview: FC<Props> = ({ data }) => (
  <div>
    <table>
      {data.allMarkdownRemark.edges.map((edge) => (
        <tr key={edge.node.id}>
          <td>{edge.node.frontmatter.title}</td>
          <td>{edge.node.fields.slug}</td>
        </tr>
      ))}
    </table>
  </div>
)

export default PostOverview
